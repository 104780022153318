<!-- Start Header Area -->
<div class="header-area p-absolute">
  <!-- Start Navbar Area -->
  <div class="navbar-area navbar-style-five consulting-color">
    <div class="noke-responsive-nav">
      <div class="container">
        <div class="noke-responsive-menu">
          <div class="logo">
            <a href="#."><img src="assets/img/logo.png" alt="logo" /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="noke-nav">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="home"
          ><img src="../../assets/img/company-logo.svg" alt="logo" width="110"
        /></a>

        <div class="collapse navbar-collapse mean-menu">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a routerLink="home" class="nav-link"> Home </a>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" routerLink="product-engineering"
                    >Product Engineering</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="digital-solutions"
                    >Digital Solutions</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="talent-solutions"
                    >Talent Solutions</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="user-experience"
                    >User Experience</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="speciality-services"
                    >Speciality Services</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" routerLink="startup-accelerator"
                    >Startup Accelerator</a
                  >
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item megamenu">
              <a
                routerLink="product-engineering"
                routerLinkActive="active"
                class="nav-link"
              >
                Product Engineering
              </a>
            </li> -->

            <!-- <li class="nav-item">
              <a
                routerLink="digital-solutions"
                routerLinkActive="active"
                class="nav-link"
              >
                Digital Solutions
              </a>
            </li> -->

            <!-- <li class="nav-item">
              <a
                routerLink="talent-solutions"
                routerLinkActive="active"
                class="nav-link"
              >
                Talent Solutions
              </a>
            </li> -->

            <!-- <li class="nav-item">
              <a
                routerLink="user-experience"
                routerLinkActive="active"
                class="nav-link"
              >
                User Experience
              </a>
            </li> -->

            <!-- <li class="nav-item">
              <a
                routerLink="speciality-services"
                routerLinkActive="active"
                class="nav-link"
              >
                Speciality Services
              </a>
            </li> -->

            <!-- <li class="nav-item">
              <a
                routerLink="startup-accelerator"
                routerLinkActive="active"
                class="nav-link"
              >
                Startup Accelerator
              </a>
            </li> -->
            <li class="nav-item">
              <a
                routerLink="careers"
                routerLinkActive="active"
                class="nav-link"
              >
                Careers
              </a>
            </li>
            <!-- <li class="nav-item">
              <a routerLink="#" routerLinkActive="active" class="nav-link">
                About
              </a>
            </li> -->
            <li class="nav-item">
              <a
                routerLink="contact"
                routerLinkActive="active"
                class="nav-link"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- <div class="others-option-for-responsive">
      <div class="container">
        <div class="dot-menu">
          <div class="inner">
            <div class="circle circle-one"></div>
            <div class="circle circle-two"></div>
            <div class="circle circle-three"></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- End Navbar Area -->
</div>
<!-- End Header Area -->
